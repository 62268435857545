import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ui-date-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_date_picker, {
      modelValue: $setup.currentValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.currentValue) = $event)),
      type: "datetime",
      placeholder: "Виберіть дату та час",
      format: "DD.MM.YYYY HH:mm",
      "value-format": "DD.MM.YYYY HH:mm",
      clearable: $props.clearable
    }, null, 8 /* PROPS */, ["modelValue", "clearable"])
  ]))
}