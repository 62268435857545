import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import CovenantResponse from '@/models/content/CovenantResponse';
import {RawCovenantResponse} from '@/types/RawCovenantResponse';

export interface CovenantResponseStore {
  covenantResponses: Dictionary<CovenantResponse>
}

const covenantResponse = {
  namespaced: true,
  state: () => ({
    covenantResponses: {},
  }),
  mutations: {
    setCovenantResponse (state, data: RawCovenantResponse) {
      const mailing = state.covenantResponses[data._id]
      if (mailing) {
        mailing.setData(data)
      } else {
        state.covenantResponses[data._id] = new CovenantResponse(data)
      }
    }
  },
  getters: {},
  actions: {
    async getList (context, {query = {}, size = 10000, from = 0, search}: {query: any, size: number, from: number, search: string}) {
      const {documents: polls, total} = await apiRequest({method: API_METHODS.covenantResponse.get.list, data: {query, search, size, from, sort: {'_ct': -1}}})

      const list: CovenantResponse[] = []

      for (const poll of polls as RawCovenantResponse[]) {
        context.commit('setCovenantResponse', poll)
        list.push(context.state.covenantResponses[poll._id])
      }

      return {documents: list, total}
    }
  }
} as Module<CovenantResponseStore, RootState>;

export default covenantResponse;