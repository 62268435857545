import ModelInterface from '@/models/ModelInterface'
import {RawPollResponse} from '@/types/RawPollResponse'
import {DateTime} from 'luxon'

export default class PollResponse extends ModelInterface<RawPollResponse> {
  public getResponseByQuestId (id: string) {
    const response = this.response.find(res => res.questId === id)
    return response
  }

  public getDateText (ts: number): string {
    return DateTime.fromMillis(ts).toFormat('dd.MM.yyyy HH:mm')
  }

  get status () {
    return this.data.status || ''
  }

  get userPhone (): string {
    return this.data.user?.phone || ''
  }

  get pollId () {
    return this.data.pollId
  }

  get userFullName (): string {
    let fullName = ''
    fullName += this.data.user?.lastName ? this.data.user?.lastName + ' ' : ''
    fullName += this.data.user?.firstName ? this.data.user?.firstName + ' ' : ''
    fullName += this.data.user?.middleName ? this.data.user?.middleName + ' ' : ''
    return fullName;
  }

  get userMsgrs () {
    return this.data.user?.msgrs
  }

  get response () {
    return this.data.response || []
  }

  get tsText () {
    return this.data._ts ? this.getDateText(this.data._ts) : ''
  }
}