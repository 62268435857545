import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-header" }
const _hoisted_2 = { class: "main-header__logo" }
const _hoisted_3 = { class: "main-header__bots" }
const _hoisted_4 = { class: "main-header__usermenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Logo"], null, {
        default: _withCtx(() => [
          _createTextVNode("BotMaster")
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      ($setup.isAuthenticated)
        ? (_openBlock(), _createBlock($setup["BotsDropdownMenu"], { key: 0 }))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($setup.isAuthenticated)
        ? (_openBlock(), _createBlock($setup["UserMenu"], { key: 0 }))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}