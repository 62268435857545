import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-menu"
}
const _hoisted_2 = { class: "user-menu__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return ($setup.currentAdmin)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_dropdown, {
          class: "user-menu__dropdown",
          trigger: "click"
        }, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_dropdown_item, { onClick: $setup.logout }, {
                  default: _withCtx(() => [
                    _createTextVNode("Вийти")
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString($setup.currentAdmin.name), 1 /* TEXT */),
              _createVNode($setup["UiAvatar"], {
                name: $setup.currentAdmin.name[0]
              }, null, 8 /* PROPS */, ["name"])
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : _createCommentVNode("v-if", true)
}