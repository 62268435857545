import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawUserAttr} from '@/types/RawUserAttr';
import UserAttr from '@/models/content/UserAttr'

export interface UserAttrsStore {
  userAttrsByIdMap: Dictionary<UserAttr>,
  userAttrsBySlugMap: Dictionary<UserAttr>,
}

const content = {
  namespaced: true,
  state: () => ({
    userAttrsByIdMap: {},
    userAttrsBySlugMap: {}
  }),
  mutations: {
    setUserAttr(state, data: RawUserAttr) {
      const userAttr = state.userAttrsByIdMap[data._id]
      if (userAttr) {
        userAttr.setData(data)
      } else {
        const model = new UserAttr(data)
        state.userAttrsByIdMap[data._id] = model
        state.userAttrsBySlugMap[data.slug] = model
      }
    }
  },
  getters: {},
  actions: {
    async getUserAttr (context, id: string) {
      const {userAttr} = await apiRequest({method: API_METHODS.userAttrs.get.userAttrs, data: {id}})
      if (!userAttr) {
        return null
      }
      context.commit('setUserAttr', userAttr)
      return context.state.userAttrsByIdMap[userAttr._id]
    },
    async getUserAttBySlug (context, data) {
      const {userAttr} = await apiRequest({method: API_METHODS.userAttrs.get.bySlug, data})
      if (!userAttr) {
        return null
      }
      context.commit('setUserAttr', userAttr)
      return context.state.userAttrsByIdMap[userAttr._id]
    },
    async getList (context, {query = {}}): Promise<{list: UserAttr[], mapById: Dictionary<UserAttr>, mapBySlug: Dictionary<UserAttr> }> {
      const {userAttrs}: {userAttrs: RawUserAttr[]} = await apiRequest({method: API_METHODS.userAttrs.get.list, data: {query}})
      if (!userAttrs) {
        return {list: [], mapById: context.state.userAttrsByIdMap, mapBySlug: context.state.userAttrsBySlugMap}
      }

      const list = []
      for (const attr of userAttrs) {
        context.commit('setUserAttr', attr)
        list.push(context.state.userAttrsByIdMap[attr._id])
      }
      return {list, mapById: context.state.userAttrsByIdMap, mapBySlug: context.state.userAttrsBySlugMap}
    },
    async createUserAttr (_, data) {
      return await apiRequest({method: API_METHODS.userAttrs.create.userAttr, data: {userAttr: data}})
    },
    async updateUserAttr (_, {id, data}) {
      return await apiRequest({method: API_METHODS.userAttrs.update.userAttr, data: {id, fields: data}})
    },
    async deleteUserAttr (_, id) {
      return await apiRequest({method: API_METHODS.userAttrs.delete.userAttr, data: {id}})
    },
  }
} as Module<UserAttrsStore, RootState>;

export default content;