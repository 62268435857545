import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "app__header" }
const _hoisted_3 = { class: "app__wrapper" }
const _hoisted_4 = { class: "app__content" }
const _hoisted_5 = {
  key: 0,
  class: "app__sidebar"
}
const _hoisted_6 = { class: "app__view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["MainHeader"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        ($setup.isAuthenticated)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode($setup["Sidebar"])
            ]))
          : _createCommentVNode("v-if", true),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_router_view)
        ])), [
          [_directive_loading, $setup.isLoading]
        ])
      ])
    ])
  ]))
}