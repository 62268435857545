import {ADMIN_PERMISSION_CONTENT_NODES_CREATE, ADMIN_PERMISSION_CONTENT_NODES_GET, ADMIN_PERMISSION_MAILINGS_CREATE, ADMIN_PERMISSION_MAILINGS_GET, ADMIN_PERMISSION_POLLS_CREATE, ADMIN_PERMISSION_POLLS_GET, ADMIN_PERMISSION_USERS_GET, ADMIN_PERMISSION_USER_ATTRS_GET, ADMIN_PERMISSION_COVENANTS_CREATE, ADMIN_PERMISSION_COVENANTS_GET} from './constants';

export interface navItem {
  name: string,
  route?: string,
  children?: navItem[],
  access?: string[]
}

const navigation: navItem[] = [
  {
    name: 'Структура',
    route: '/content',
    access: [
      ADMIN_PERMISSION_CONTENT_NODES_CREATE, 
      ADMIN_PERMISSION_CONTENT_NODES_GET
    ],
    children: [
      {
        name: 'Створити кнопку',
        route: '/content/create',
        access: [ADMIN_PERMISSION_CONTENT_NODES_CREATE]
      },
      {
        name: 'Структура бота',
        route: '/content/tree',
        access: [ADMIN_PERMISSION_CONTENT_NODES_GET]
      },
    ]
  },
  {
    name: 'Розсилка',
    route: '/mailing',
    access: [
      ADMIN_PERMISSION_MAILINGS_GET,
      ADMIN_PERMISSION_MAILINGS_CREATE
    ],
    children: [
      {
        name: 'Створити розсилку',
        route: '/mailings/create',
        access: [ADMIN_PERMISSION_MAILINGS_CREATE]
      },
      {
        name: 'Список розсилок',
        route: '/mailings/list',
        access: [ADMIN_PERMISSION_MAILINGS_GET]
      },
    ]
  },
  {
    name: 'Опитування',
    route: '/polls',
    access: [ADMIN_PERMISSION_POLLS_CREATE, ADMIN_PERMISSION_POLLS_GET],
    children: [
      {
        name: 'Створити опитування',
        route: '/polls/create',
        access: [ADMIN_PERMISSION_POLLS_CREATE]
      },
      {
        name: 'Список опитувань',
        route: '/polls/list',
        access: [ADMIN_PERMISSION_POLLS_GET]
      },
    ]
  },
  {
    name: 'Чеклісти',
    route: '/covenants',
    access: [ADMIN_PERMISSION_COVENANTS_CREATE, ADMIN_PERMISSION_COVENANTS_GET],
    children: [
      {
        name: 'Створити чекліст',
        route: '/covenants/create',
        access: [ADMIN_PERMISSION_COVENANTS_CREATE]
      },
      {
        name: 'Список чеклістів',
        route: '/covenants/list',
        access: [ADMIN_PERMISSION_COVENANTS_GET]
      },
    ]
  },
  {
    name: 'Користувачі',
    route: '/users',
    access: [ADMIN_PERMISSION_USERS_GET, ADMIN_PERMISSION_USER_ATTRS_GET],
    children: [
      {
        name: 'Список користувачів',
        route: '/users/list',
        access: [ADMIN_PERMISSION_USERS_GET]
      },
      {
        name: 'Атрибути користувачів',
        route: '/users/attrs',
        access: [ADMIN_PERMISSION_USER_ATTRS_GET]
      }
    ]
  }
]

export default navigation;