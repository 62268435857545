import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawCovenant} from '@/types/RawCovenant';
import Covenant from '@/models/content/Covenant';

export interface CovenantsStore {
  covenants: Dictionary<Covenant>
}

const covenants = {
  namespaced: true,
  state: () => ({
    covenants: {},
  }),
  mutations: {
    setCovenant (state, data: RawCovenant) {
      const mailing = state.covenants[data._id]
      if (mailing) {
        mailing.setData(data)
      } else {
        state.covenants[data._id] = new Covenant(data)
      }
    }
  },
  getters: {},
  actions: {
    async createCovenant (_, data) {
      return await apiRequest({method: API_METHODS.covenants.create.covenant, data: {covenant: data}})
    },
    async getCovenant (context, id: string) {
      const {covenant} = await apiRequest({method: API_METHODS.covenants.get.covenant, data: {id}})
      if (!covenant) {
        return null
      }
      context.commit('setCovenant', covenant)
      return context.state.covenants[covenant._id]
    },
    async searchCovenants (context, {query = {}, size = 10000, from = 0, search}: {query: any, size: number, from: number, search: string}) {
      const {documents: covenants, total} = await apiRequest({method: API_METHODS.covenants.get.covenantsList, data: {query, search, size, from, sort: {'_ct': -1}}})

      const list: Covenant[] = []

      for (const covenant of covenants as RawCovenant[]) {
        context.commit('setCovenant', covenant)
        list.push(context.state.covenants[covenant._id])
      }

      return {mailings: list, total}
    },
    async updateCovenant (_, {id, data}) {
      return await apiRequest({method: API_METHODS.covenants.update.covenant, data: {id, fields: data}})
    },
    async planeCovenant (_, {id, startDate, endDate}) {
      return await apiRequest({method: API_METHODS.covenants.update.planeCovenant, data: {id, startDate, endDate}})
    },
    async cancelCovenant (_, {id}) {
      return await apiRequest({method: API_METHODS.covenants.update.cancelCovenant, data: {id}})
    },
    async deleteCovenant (_, {id}) {
      return await apiRequest({method: API_METHODS.covenants.delete.covenant, data: {id}})
    },
    async closeCovenant (_, {id}) {
      return await apiRequest({method: API_METHODS.covenants.update.closeCovenant, data: {id}})
    }
  }
} as Module<CovenantsStore, RootState>;

export default covenants;