import ModelInterface from '@/models/ModelInterface'
import {RawPoll, PollQuestResult} from '@/types/RawPoll'
import {NodeText} from '@/types/RawNode'
import {DateTime} from 'luxon'
import {PollTemplateData} from '@/types/RawPollTemplate'

export default class Poll extends ModelInterface<RawPoll> {

  public getDateText (ts: number): string {
    return DateTime.fromMillis(ts).toFormat('dd.MM.yyyy HH:mm')
  }

  public getQuestResults (questId: string): PollQuestResult | null {
    return this.data.results?.find(quest => quest.questId === questId) || null
  }

  public generateTemplateData (): PollTemplateData {
    return {
      title: this.title,
      description: this.description,
      images: this.data.images,
      public: this.public,
      sendToEmail: this.data.sendToEmail,
      emailSubject: this.emailSubject,
      recipients: this.recipients,
      quests: this.quests,
    }
  }

  get title () {
    return this.data.title || ''
  }

  get description () {
    return this.data.description || ''
  }

  get status () {
    return this.data.status || ''
  }

  get recipients () {
    return this.data.recipients
  }

  get startDate () {
    return this.data.startDate
  }

  set startDate (date: number) {
    this.data.startDate = date
  }

  get endDate () {
    return this.data.endDate
  }

  set endDate (date: number) {
    this.data.endDate = date
  }

  get startDateText () {
    return this.startDate ? this.getDateText(this.startDate) : 'Не встановлено'
  }

  get endDateText () {
    return this.endDate ? this.getDateText(this.endDate) : 'Без обмеження'
  }

  get ctText () {
    return this._ct ? this.getDateText(this._ct) : ''
  }

  get tsText () {
    return this._ts ? this.getDateText(this._ts) : ''
  }

  get results () {
    return this.data.results || []
  }

  get answTotal () {
    return this.data.answTotal || 0
  }

  get quests () {
    return this.data.quests || []
  }

  get emailSubject () {
    return this.data.emailSubject || ''
  }
  

  get public () {
    return this.data.public || false
  }

  set public (value: boolean) {
    this.data.public = value
  }

}