import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawViberBg} from '@/types/RawViberBg';

export interface ViberBgState {}

const module = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  getters: {},
  actions: {
    async getDocument (_, id: string) {
      const {document} = await apiRequest({method: API_METHODS.viberBg.get.document, data: {id}})
      return document
    },
    async getList (context, {query = {}, size = 10000, from = 0}: {query: any, size: number, from: number}): Promise<RawViberBg[]> {
      const response = await apiRequest({method: API_METHODS.viberBg.get.list, data: {query, size, from, sort: {'_ct': 1}}})
      return response
    },
    async createDocument (_, data) {
      return await apiRequest({method: API_METHODS.viberBg.create.document, data: {document: data}})
    },
    async updateDocument (_, {id, data}) {
      return await apiRequest({method: API_METHODS.viberBg.update.document, data: {id, fields: data}})
    },
    async deleteDocument (_, id: string) {
      return await apiRequest({method: API_METHODS.viberBg.delete.document, data: {id}})
    }
  }
} as Module<ViberBgState, RootState>;

export default module;