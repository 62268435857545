interface BaseModel {
  _id: string
  _ct: number
  _ts: number
}

export default class ModelInterface<dataType> {

  protected data!: dataType & BaseModel

  constructor (data: dataType) {
    this.setData(data as dataType & BaseModel)
  }

  public setData (data: dataType) {
    this.data = data as dataType & BaseModel
  }

  public getData () {
    return this.data
  }

  public getId () {
    return this.data._id
  }

  public toJSON () {
    return this.getId()
  }

  get _id () {
    return this.getId()
  }
  get _ct (): number{
    return this.data._ct
  }
  get _ts (): number{
    return this.data._ct
  }
}
