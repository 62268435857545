import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {store, key} from './store'

import packageJson from '../package.json'

import UiContainer from './components/ui/UiContainer.vue'
import UiPageTitle from './components/ui/UiPageTitle.vue'
import UiBlock from './components/ui/UiBlock.vue'
import UiTabs from './components/ui/UiTabs.vue'
import UiHelpTooltip from './components/ui/UiHelpTooltip.vue'
import UiEmptyImage from './components/ui/UiEmptyImage.vue'
import UiAvatar from './components/ui/UiAvatar.vue'
import UiTable from './components/ui/UiTable.vue'
import UiPagination from './components/ui/UiPagination.vue'
import UiDatePicker from './components/ui/UiDatePicker.vue'
import UiDatePickerSeparate from './components/ui/UiDatePickerSeparate.vue'
import UiMessengerIcon from './components/ui/UiMessengerIcon.vue'

import ElementPlus from 'element-plus'
import uk from 'element-plus/es/locale/lang/uk'
import dayjs from 'dayjs'
import dayjsUkLocale from 'dayjs/locale/uk'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import {RawBot} from './types/RawBot'
import {BOT_ACTIVE_LS_KEY} from './constants'

async function init () {

  store.state.appVersion = packageJson.version

  console.log('App:', packageJson.name);
  console.log('version', packageJson.version);

  dayjs.locale(dayjsUkLocale)

  const app = createApp(App)

  app.component('UiContainer', UiContainer)
  app.component('UiPageTitle', UiPageTitle)
  app.component('UiBlock', UiBlock)
  app.component('UiTabs', UiTabs)
  app.component('UiHelpTooltip', UiHelpTooltip)
  app.component('UiEmptyImage', UiEmptyImage)
  app.component('UiAvatar', UiAvatar)
  app.component('UiTable', UiTable)
  app.component('UiPagination', UiPagination)
  app.component('UiDatePicker', UiDatePicker)
  app.component('UiDatePickerSeparate', UiDatePickerSeparate)
  app.component('UiMessengerIcon', UiMessengerIcon)
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  app.use(store, key)
  app.use(router)
  app.use(ElementPlus, {
    locale: uk
  })

  app.mount('#app')
}

init();
