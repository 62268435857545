import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import User from '@/models/content/User';
import {RawUser} from '@/types/RawUser';

export interface UsersStore {
  users: Dictionary<User>
}

const users = {
  namespaced: true,
  state: () => ({
    users: {},
  }),
  mutations: {
    setUser (state, userData: RawUser) {
      const user = state.users[userData._id]
      if (user) {
        user.setData(userData)
      } else {
        state.users[userData._id] = new User(userData)
      }
    }
  },
  getters: {},
  actions: {
    async getUser (context, id: string) {
      const {user} = await apiRequest({method: API_METHODS.users.get.user, data: {id}})
      if (!user) {
        return null
      }
      context.commit('setUser', user)
      return context.state.users[user._id]
    },
    async deleteUser (context, id: string) {
      const response = await apiRequest({method: API_METHODS.users.delete.user, data: {id}})
      return response
    },
    async searchUsers (context, {query = {}, size = 10000, from = 0, search}: {query: any, size: number, from: number, search: string}) {
      const {users, total} = await apiRequest({method: API_METHODS.users.search.users, data: {query, search, size, from, sort: {'_ct': -1}}})

      const list: User[] = []

      for (const user of users) {
        list.push(new User(user))
      }

      return {users: list, total}
    },
    async updateUser (_, {id, data}) {
      return await apiRequest({method: API_METHODS.users.update.user, data: {id, fields: data}})
    }
  }
} as Module<UsersStore, RootState>;

export default users;