import {BOT_MESSENGER_STATUS_ACTIVE} from '@/constants';
import ModelInterface from '@/models/ModelInterface'
import {MessengersListType, RawBot} from '@/types/RawBot'


export default class Bot extends ModelInterface<RawBot> {

 public hasActiveMessengers (): boolean {
  for (const name in this.messengers) {
    const messenger = this.messengers[name as MessengersListType];
    if (messenger?.status === BOT_MESSENGER_STATUS_ACTIVE) {
      return true
    }
  }
  return false
 }

 public hasBindedMessengers (): boolean {
  for (const name in this.messengers) {
    const messenger = this.messengers[name as MessengersListType];
    if (messenger) {
      return true
    }
  }
  return false
 }

  get name () {
    return this.data.name
  }

  set name (val: string) {
    this.data.name = val
  }

  get description () {
    return this.data.description
  }

  set description (val: string) {
    this.data.description = val
  }

  get status () {
    return this.data.status
  }

  get messengers () {
    return this.data.messengers || {telegram: null, viber: null}
  }
}