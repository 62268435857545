import {NODE_POST_TYPE_ATTACHMENTS, NODE_POST_TYPE_IMAGES, NODE_POST_TYPE_TEXT} from '@/constants'
import ModelInterface from '@/models/ModelInterface'
import {NodeAttachment, NodeAttachmentsValue, NodeGroupItem, NodeImages, NodeImagesValue, NodeText, NodeTextValue, RawNode} from '@/types/RawNode'
import {Dictionary} from '@/types/types'

export const PostTypes: Dictionary<{label: string, empty: NodeText | NodeImages | NodeAttachment}> = {
  [NODE_POST_TYPE_TEXT]: {label: 'Текст', empty: {type: NODE_POST_TYPE_TEXT, value: {default: ''}} as NodeText},
  [NODE_POST_TYPE_IMAGES]: {label: 'Світлини', empty: {type: NODE_POST_TYPE_IMAGES, value: []} as NodeImages},
  [NODE_POST_TYPE_ATTACHMENTS]: {label: 'Файли', empty: {type: NODE_POST_TYPE_ATTACHMENTS, value: []} as NodeAttachment}
}

export const groupEmptyItem: NodeGroupItem = {
  text: {
    default: '',
    viber: '',
    telegram: ''
  },
  image: ''
}

export default class ContentNode extends ModelInterface<RawNode> {
  public getTitle () {
    return this.data.title
  }

  public getTitleText () {
    return this.getTitle().text
  }

  public getParentId () {
    return this.data.parentId
  }

  public getPost () {
    return this.data.post || []
  }


  public getGroup () {
    return this.data.group || []
  }

  static getPostTyleLabel (type: string): string {
    return PostTypes[type].label || 'Невідмий тип'
  }

  static getPostEmpty (type: string): NodeText | NodeImages | NodeAttachment {
    return PostTypes[type].empty
  }
}