<template>
  <div class="ui-container">
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/core.scss';

.ui-container {
  max-width: $max-content-width;
  // margin: 0 auto;
  // padding: 0 $main-padding;
}
</style>