import {CREDIT_OFFICERS_LIST} from '@/constants'
import ModelInterface from '@/models/ModelInterface'
import {RawCovenant, CovenantQuestResult} from '@/types/RawCovenant'
import {CovenantTemplateData} from '@/types/RawCovenantTemplate'
import {NodeText} from '@/types/RawNode'
import {DateTime} from 'luxon'

export default class Covenant extends ModelInterface<RawCovenant> {

  public getDateText (ts: number): string {
    return DateTime.fromMillis(ts).toFormat('dd.MM.yyyy HH:mm')
  }

  public getDateTextfromISO (date: string): string {
    return DateTime.fromISO(date).toFormat('dd.MM.yyyy')
  }

  public getQuestResults (questId: string): CovenantQuestResult | null {
    return this.data.results?.find(quest => quest.questId === questId) || null
  }
  
  public generateTemplateData (): CovenantTemplateData {
    return {
      title: this.title,
      description: this.description,
      images: this.data.images,
      public: this.public,
      recipients: this.recipients,
      quests: this.quests,
      covenantStartDate: this.covenantStartDate,
      covenantEndDate: this.covenantEndDate,
      creditStartDate: this.creditStartDate,
      creditEndDate: this.creditEndDate,
      creditName: this.creditName,
      officerId: this.officerId
    }
  }

  get creditName () {
    return this.data.creditName
  }

  get officerId () {
    return this.data.officerId
  }

  get officerName () {
    return CREDIT_OFFICERS_LIST.find(item => item.value === this.officerId)?.label || 'Невідомий офіцер'
  }

  get seqNumber () {
    return this.data._seqNumber
  }

  get title () {
    return this.data.title || ''
  }

  get description () {
    return this.data.description || ''
  }

  get status () {
    return this.data.status || ''
  }

  get recipients () {
    return this.data.recipients
  }

  get startDate () {
    return this.data.startDate
  }

  set startDate (date: number) {
    this.data.startDate = date
  }

  get endDate () {
    return this.data.endDate
  }

  set endDate (date: number) {
    this.data.endDate = date
  }

  get startDateText () {
    return this.startDate ? this.getDateText(this.startDate) : 'Не встановлено'
  }

  get endDateText () {
    return this.endDate ? this.getDateText(this.endDate) : 'Без обмеження'
  }

  get covenantStartDate () {
    return this.data.covenantStartDate
  }

  get covenantStartDateText () {
    return this.covenantStartDate ? this.getDateTextfromISO(this.covenantStartDate) : 'Не встановлено'
  }

  get covenantEndDate () {
    return this.data.covenantEndDate
  }

  get covenantEndDateText () {
    return this.covenantEndDate ? this.getDateTextfromISO(this.covenantEndDate) : 'Не встановлено'
  }

  get creditStartDate () {
    return this.data.creditStartDate
  }

  get creditStartDateText () {
    return this.creditStartDate ? this.getDateTextfromISO(this.creditStartDate) : 'Не встановлено'
  }

  get creditEndDate () {
    return this.data.creditEndDate
  }

  get creditEndDateText () {
    return this.creditEndDate ? this.getDateTextfromISO(this.creditEndDate) : 'Не встановлено'
  }

  get ctText () {
    return this._ct ? this.getDateText(this._ct) : ''
  }

  get tsText () {
    return this._ts ? this.getDateText(this._ts) : ''
  }

  get results () {
    return this.data.results || []
  }

  get answTotal () {
    return this.data.answTotal || 0
  }

  get quests () {
    return this.data.quests || []
  }
  

  get public () {
    return this.data.public || false
  }

  set public (value: boolean) {
    this.data.public = value
  }

}