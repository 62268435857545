import {ViberSoursesSizes} from './types/RawViberBg'
import {Dictionary} from './types/types'

export const API_BASE_URL = process.env.VUE_APP_BOTS_CONTENT_BASE_URL as string

export const S3_BASE_URL = process.env.VUE_APP_S3_PROXY_BASE_URL as string
export const S3_TOKEN = process.env.VUE_APP_S3_PROXY_TOKEN as string
export const S3_BUCKET_NAME = '/bots-content'
export const S3_IMAGES_PATH = '/images/'
export const S3_FILES_PATH = '/files/'
export const S3_VIBER_BGS_PATH = '/viber-bgs/'

export const CDN_URL = process.env.VUE_APP_CDN_URL as string

export const API_METHODS = {
  auth: {
    login: 'auth.login'
  },
  contentNode: {
    create: {
      node: 'contentNode.create.node'
    },
    get: {
      node: 'contentNode.get.node',
      children: 'contentNode.get.children',
      tree: 'contentNode.get.tree',
      list: 'contentNode.get.list',
      chain: 'contentNode.get.chain'
    },
    update: {
      node: 'contentNode.update.node',
      status: 'contentNode.update.status',
      parent: 'contentNode.update.parent'
    },
    delete: {
      node: 'contentNode.delete.node'
    }
  },
  bots: {
    create: {
      bot: 'bots.create.bot'
    },
    get: {
      bot: 'bots.get.bot',
      list: 'bots.get.list',
      availableServices: 'bots.get.availableServices',
    },
    update: {
      bot: 'bots.update.bot',
      bindMessenger: 'bots.update.bindMessenger',
      unBindMessenger: 'bots.update.unBindMessenger'
    }
  },
  users: {
    get: {
      user: 'users.get.user'
    },
    search: {
      users: 'users.search.users'
    },
    delete: {
      user: 'users.delete.user'
    },
    update: {
      user: 'users.update.user'
    }
  },
  userAttrs: {
    get: {
      userAttrs: 'userAttrs.get.userAttr',
      list: 'userAttrs.get.list',
      bySlug: 'userAttrs.get.bySlug',
    },
    create: {
      userAttr: 'userAttrs.create.userAttr'
    },
    update: {
      userAttr: 'userAttrs.update.userAttr'
    },
    delete: {
      userAttr: 'userAttrs.delete.userAttr'
    }
  },
  mailings: {
    create: {
      mailing: 'mailings.create.mailing',
      sendMailing: 'mailings.create.sendMailing'
    },
    get: {
      mailing: 'mailings.get.mailing',
      list: 'mailings.get.list'
    },
    update: {
      mailing: 'mailings.update.mailing',
      planeMailing: 'mailings.update.planeMailing'
    },
    delete: {
      mailing: 'mailings.delete.mailing',
    }
  },
  polls: {
    create: {
      poll: 'polls.create.poll'
    },
    get: {
      poll: 'polls.get.poll',
      pollsList: 'polls.get.pollsList'
    },
    update: {
      poll: 'polls.update.poll',
      planePoll: 'polls.update.planePoll',
      cancelPoll: 'polls.update.cancelPoll',
      closePole: 'polls.update.closePoll'
    },
    delete: {
      poll: 'polls.delete.poll',
    }
  },
  pollsTemplates: {
    create: {
      template: 'pollsTemplates.create.template'
    },
    get: {
      template: 'pollsTemplates.get.tempaltes',
      templatesList: 'pollsTemplates.get.templatesList'
    },
    delete: {
      template: 'pollsTemplates.delete.template',
    }
  },
  pollResponse: {
    get: {
      list: 'pollResponse.get.list'
    }
  },
  covenants: {
    create: {
      covenant: 'covenants.create.covenant'
    },
    get: {
      covenant: 'covenants.get.covenant',
      covenantsList: 'covenants.get.covenantsList'
    },
    update: {
      covenant: 'covenants.update.covenant',
      planeCovenant: 'covenants.update.planeCovenant',
      cancelCovenant: 'covenants.update.cancelCovenant',
      closeCovenant: 'covenants.update.closeCovenant'
    },
    delete: {
      covenant: 'covenants.delete.covenant',
    }
  },
  covenantsTemplates: {
    create: {
      template: 'covenantsTemplates.create.template'
    },
    get: {
      template: 'covenantsTemplates.get.tempaltes',
      templatesList: 'covenantsTemplates.get.templatesList'
    },
    delete: {
      template: 'covenantsTemplates.delete.template',
    }
  },
  covenantResponse: {
    get: {
      list: 'covenantResponse.get.list'
    }
  },
  viberBg: {
    get: {
      document: 'viberBg.get.document',
      list: 'viberBg.get.list'
    },
    create: {
      document: 'viberBg.create.document'
    },
    update: {
      document: 'viberBg.update.document'
    },
    delete: {
      document: 'viberBg.delete.document'
    }
  }
}

// API ERROR CODES
export const E_ACCESS = -32604
export const E_LOGIN = -326040

// ADMINS
export const ADMIN_PERMISSION_BOTS_CREATE = 'BOTS_CREATE'
export const ADMIN_PERMISSION_BOTS_UPDATE = 'BOTS_UPDATE'

export const ADMIN_PERMISSION_CONTENT_NODES_CREATE = 'CONTENT_NODES_CREATE'
export const ADMIN_PERMISSION_CONTENT_NODES_GET = 'CONTENT_NODES_GET'
export const ADMIN_PERMISSION_CONTENT_NODES_UPDATE = 'CONTENT_NODES_UPDATE'
export const ADMIN_PERMISSION_CONTENT_NODES_DELETE = 'CONTENT_NODES_DELETE'

export const ADMIN_PERMISSION_MAILINGS_CREATE = 'MAILINGS_CREATE'
export const ADMIN_PERMISSION_MAILINGS_GET = 'MAILINGS_GET'
export const ADMIN_PERMISSION_MAILINGS_UPDATE = 'MAILINGS_UPDATE'
export const ADMIN_PERMISSION_MAILINGS_DELETE = 'MAILINGS_DELETE'

export const ADMIN_PERMISSION_POLLS_CREATE = 'POLLS_CREATE'
export const ADMIN_PERMISSION_POLLS_GET = 'POLLS_GET'
export const ADMIN_PERMISSION_POLLS_UPDATE = 'POLLS_UPDATE'
export const ADMIN_PERMISSION_POLLS_DELETE = 'POLLS_DELETE'

export const ADMIN_PERMISSION_COVENANTS_CREATE = 'COVENANTS_CREATE'
export const ADMIN_PERMISSION_COVENANTS_GET = 'COVENANTS_GET'
export const ADMIN_PERMISSION_COVENANTS_UPDATE = 'COVENANTS_UPDATE'
export const ADMIN_PERMISSION_COVENANTS_DELETE = 'COVENANTS_DELETE'

export const ADMIN_PERMISSION_USERS_CREATE = 'USERS_CREATE'
export const ADMIN_PERMISSION_USERS_GET = 'USERS_GET'
export const ADMIN_PERMISSION_USERS_UPDATE = 'USERS_UPDATE'
export const ADMIN_PERMISSION_USERS_DELETE = 'USERS_DELETE'

export const ADMIN_PERMISSION_USER_ATTRS_CREATE = 'USER_ATTRS_CREATE'
export const ADMIN_PERMISSION_USER_ATTRS_GET = 'USER_ATTRS_GET'
export const ADMIN_PERMISSION_USER_ATTRS_UPDATE = 'USER_ATTRS_UPDATE'
export const ADMIN_PERMISSION_USER_ATTRS_DELETE = 'USER_ATTRS_DELETE'

// NODES
export const NODE_POST_TYPE_TEXT = 'text'
export const NODE_POST_TYPE_IMAGES = 'images'
export const NODE_POST_TYPE_ATTACHMENTS = 'attachments'
export const NODE_ROOT_ID = '00000000-0000-0000-0000-000000000000'

// STATUSES
export const NODE_STATUS_ARCHIVE: 'archive' = 'archive';
export const NODE_STATUS_DRAFT: 'draft' = 'draft';
export const NODE_STATUS_IN_TEST: 'in-test' = 'in-test';
export const NODE_STATUS_PUBLISHED: 'published' = 'published';

export const NODE_STATUS_ARCHIVE_NAME = 'Архів';
export const NODE_STATUS_DRAFT_NAME = 'Чернетка';
export const NODE_STATUS_IN_TEST_NAME  = 'Тестується';
export const NODE_STATUS_PUBLISHED_NAME  = 'Опубліковано';


export const NODE_STATUS_ARCHIVE_WEIGHT = -1;
export const NODE_STATUS_DRAFT_WEIGHT = 0;
export const NODE_STATUS_IN_TEST_WEIGHT = 1;
export const NODE_STATUS_PUBLISHED_WEIGHT = 2;

export const NODE_STATUSES_MAP = {
  [NODE_STATUS_ARCHIVE]: {
    name: NODE_STATUS_ARCHIVE_NAME,
    weight: NODE_STATUS_ARCHIVE_WEIGHT
  },
  [NODE_STATUS_DRAFT]: {
    name: NODE_STATUS_DRAFT_NAME,
    weight: NODE_STATUS_DRAFT_WEIGHT
  },
  [NODE_STATUS_IN_TEST]: {
    name: NODE_STATUS_IN_TEST_NAME,
    weight: NODE_STATUS_IN_TEST_WEIGHT
  },
  [NODE_STATUS_PUBLISHED]: {
    name: NODE_STATUS_PUBLISHED_NAME,
    weight: NODE_STATUS_PUBLISHED_WEIGHT
  }
};

export const NODE_TYPE_CONTENT = 'content';
export const NODE_TYPE_CONTENT_NAME = 'Наповнення';
export const NODE_TYPE_SERVICE = 'service';
export const NODE_TYPE_SERVICE_NAME = 'Сервіс';
export const NODE_TYPES_MAP: Dictionary<string> = {
  [NODE_TYPE_CONTENT]: NODE_TYPE_CONTENT_NAME,
  [NODE_TYPE_SERVICE]: NODE_TYPE_SERVICE_NAME,
}

// VIBER SETTINGS
export const VIBER_BGS_RECOMEND_RESOLUTIONS = {
  col_1: {w: 1280, h: 210},
  col_2: {w: 640, h: 210},
  col_3: {w: 424, h: 210}
} as {[k in ViberSoursesSizes]: {w: number, h: number}}

// BOTS
export const BOT_ACTIVE_LS_KEY = 'active_bot'

export const BOT_MESSENGER_STATUS_ACTIVE = 'active'
export const BOT_MESSENGER_STATUS_PENDING = 'pending'
export const BOT_MESSENGER_STATUS_ACTIVE_NAME = 'Активний'
export const BOT_MESSENGER_STATUS_PENDING_NAME = 'Обробляється'

// USERS
export const USER_STATUS_LEAD = 'lead';
export const USER_STATUS_REGISTERED = 'registered';
export const USER_STATUS_VERIFIED = 'verified';

export const USER_STATUSES_MAP: Dictionary<{name: string}> = {
  [USER_STATUS_LEAD]: {
    name: 'Незареєстрований'
  },
  [USER_STATUS_REGISTERED]: {
    name: 'Зареєстрований'
  },
  [USER_STATUS_VERIFIED]: {
    name: 'Верифікований'
  }
}
export const USER_LIST_ACTIVE_COLS_KEY_NAME = 'user_list_active_cols'

// MAILINGS
export const MAILING_STATUS_DRAFT = 'draft';
export const MAILING_STATUS_PLANED = 'planed';
export const MAILING_STATUS_IN_PROGRESS = 'in-progress';
export const MAILING_STATUS_SENT = 'sent';
export const MAILING_STATUS_CANCELED = 'canceled';

export const MAILING_STATUS_DRAFT_NAME = 'Чернетка';
export const MAILING_STATUS_PLANED_NAME = 'Запланована';
export const MAILING_STATUS_IN_PROGRESS_NAME = 'Розсилається';
export const MAILING_STATUS_SENT_NAME = 'Надіслана';
export const MAILING_STATUS_CANCELED_NAME = 'Скасована';


// ATTRIBUTES
export const USER_ATTR_INPUT_TYPE_INPUT = 'input';
export const USER_ATTR_INPUT_TYPE_INPUT_NAME = 'Текст';
export const USER_ATTR_INPUT_TYPE_SELECT = 'select';
export const USER_ATTR_INPUT_TYPE_SELECT_NAME = 'Список';
export const USER_ATTR_INPUT_TYPE_MULTISELECT = 'multiselect'
export const USER_ATTR_INPUT_TYPE_MULTISELECT_NAME = 'Мультисписок'

export const USER_ATTR_TYPES_MAP = {
  [USER_ATTR_INPUT_TYPE_INPUT]: USER_ATTR_INPUT_TYPE_INPUT_NAME,
  [USER_ATTR_INPUT_TYPE_SELECT]: USER_ATTR_INPUT_TYPE_SELECT_NAME,
  [USER_ATTR_INPUT_TYPE_MULTISELECT]: USER_ATTR_INPUT_TYPE_MULTISELECT_NAME
}


// POLLS
export const POLL_STATUS_DRAFT = 'draft';
export const POLL_STATUS_PLANED = 'planed';
export const POLL_STATUS_OPENED = 'opened';
export const POLL_STATUS_COMPLETED = 'completed';
export const POLL_STATUS_CANCELED = 'canceled';

export const POLL_STATUS_DRAFT_NAME = 'Чернетка';
export const POLL_STATUS_PLANED_NAME = 'Заплановане';
export const POLL_STATUS_OPENED_NAME = 'Опубліковане';
export const POLL_STATUS_COMPLETED_NAME = 'Завершене';
export const POLL_STATUS_CANCELED_NAME = 'Скасоване';

export const POLLS_STATUSES_LIST = [
  POLL_STATUS_DRAFT,
  POLL_STATUS_PLANED,
  POLL_STATUS_OPENED,
  POLL_STATUS_COMPLETED,
  POLL_STATUS_CANCELED
]

export const POLL_RESPONSE_STATUS_OPENED = 'opened'
export const POLL_RESPONSE_STATUS_EXPERED = 'expired'
export const POLL_RESPONSE_STATUS_DONE = 'done'

export const POLL_QUEST_TYPE_RADIO = 'radio'
export const POLL_QUEST_TYPE_MULTIPLE = 'mupliple'

export const POLL_QUEST_TYPES_MAP: Dictionary<{label: string}> = {
  [POLL_QUEST_TYPE_RADIO]: {label: 'Один варіант вибору'},
  [POLL_QUEST_TYPE_MULTIPLE]: {label: 'Множинний варіант вибору'}
}

// COVENANTS
export const COVENANT_STATUS_DRAFT = 'draft';
export const COVENANT_STATUS_PLANED = 'planed';
export const COVENANT_STATUS_OPENED = 'opened';
export const COVENANT_STATUS_COMPLETED = 'completed';
export const COVENANT_STATUS_CANCELED = 'canceled';

export const COVENANT_STATUS_DRAFT_NAME = 'Чернетка';
export const COVENANT_STATUS_PLANED_NAME = 'Заплановане';
export const COVENANT_STATUS_OPENED_NAME = 'Опубліковане';
export const COVENANT_STATUS_COMPLETED_NAME = 'Завершене';
export const COVENANT_STATUS_CANCELED_NAME = 'Скасоване';

export const COVENANTS_STATUSES_LIST = [
  COVENANT_STATUS_DRAFT,
  COVENANT_STATUS_PLANED,
  COVENANT_STATUS_OPENED,
  COVENANT_STATUS_COMPLETED,
  COVENANT_STATUS_CANCELED
]

export const COVENANT_RESPONSE_STATUS_OPENED = 'opened'
export const COVENANT_RESPONSE_STATUS_EXPERED = 'expired'
export const COVENANT_RESPONSE_STATUS_DONE = 'done'

export const COVENANT_QUEST_TYPE_RADIO = 'radio'
export const COVENANT_QUEST_TYPE_MULTIPLE = 'mupliple'

export const COVENANT_QUEST_TYPES_MAP: Dictionary<{label: string}> = {
  [COVENANT_QUEST_TYPE_RADIO]: {label: 'Один варіант вибору'},
  [COVENANT_QUEST_TYPE_MULTIPLE]: {label: 'Множинний варіант вибору'}
}

export const COVENANT_RESPONSIBLE_PERSON_ATTR_SLUG = 'covenant_responsible'

export const CREDIT_OFFICERS_LIST = [
  {
    label: 'Томова Анна Сергіївна',
    value: '1'
  },
  {
    label: 'Кульгейко Михайло Олексійович',
    value: '2'
  },
  {
    label: 'Корнєєва Анастасія Олександрівна',
    value: '3'
  },
  {
    label: 'Лотоус Віктор Вікторович',
    value: '4'
  },
]