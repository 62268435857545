import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import auth from '@/services/auth'
import {store} from '@/store'
import {RawBot} from '@/types/RawBot'
import {ADMIN_PERMISSION_BOTS_CREATE, ADMIN_PERMISSION_BOTS_UPDATE, ADMIN_PERMISSION_CONTENT_NODES_CREATE, ADMIN_PERMISSION_CONTENT_NODES_GET, ADMIN_PERMISSION_CONTENT_NODES_UPDATE, ADMIN_PERMISSION_COVENANTS_CREATE, ADMIN_PERMISSION_COVENANTS_GET, ADMIN_PERMISSION_COVENANTS_UPDATE, ADMIN_PERMISSION_MAILINGS_CREATE, ADMIN_PERMISSION_MAILINGS_GET, ADMIN_PERMISSION_MAILINGS_UPDATE, ADMIN_PERMISSION_POLLS_CREATE, ADMIN_PERMISSION_POLLS_GET, ADMIN_PERMISSION_POLLS_UPDATE, ADMIN_PERMISSION_USER_ATTRS_GET, ADMIN_PERMISSION_USERS_GET, BOT_ACTIVE_LS_KEY, E_ACCESS, E_LOGIN} from '@/constants'
import {ElMessage} from 'element-plus'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: []
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/bots',
    name: 'bots',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: 'create',
        name: 'botCreate',
        component: () => import('../pages/bots/CreateBotPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_BOTS_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'botAbout',
        component: () => import('../pages/bots/AboutBotPage.vue'),
        props: true
      },
      {
        path: ':id/edit',
        name: 'botEdit',
        component: () => import('../pages/bots/EditBotPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_BOTS_UPDATE]
        },
        props: true
      }
    ]
  },
  {
    path: '/content',
    name: 'content',
    redirect: '/content/tree',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: 'tree',
        name: 'contentTree',
        component: () => import('../pages/content/TreePage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_CONTENT_NODES_GET]
        }
      },
      {
        path: 'create',
        name: 'contentCreate',
        component: () => import('../pages/content/CreatePage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_CONTENT_NODES_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'contentNode',
        component: () => import('../pages/content/ContentNodePage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_CONTENT_NODES_GET]
        }
      },
      {
        path: ':id/edit',
        name: 'contentEdit',
        component: () => import('../pages/content/EditPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_CONTENT_NODES_UPDATE]
        }
      },
    ]
  },
  {
    path: '/mailings',
    name: 'mailings',
    redirect: '/mailings/list',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: 'create',
        name: 'mailingCreate',
        component: () => import('../pages/mailings/CreateMailingPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_MAILINGS_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'mailingAbout',
        component: () => import('../pages/mailings/AboutMailingPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_MAILINGS_GET]
        }
      },
      {
        path: ':id/edit',
        name: 'mailingEdit',
        component: () => import('../pages/mailings/EditMailingPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_MAILINGS_UPDATE]
        }
      },
      {
        path: 'list',
        name: 'mailingList',
        component: () => import('../pages/mailings/ListMailingPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_MAILINGS_GET]
        }
      },
    ]
  },
  {
    path: '/polls',
    name: 'polls',
    redirect: '/polls/list',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: 'create',
        name: 'pollCreate',
        component: () => import('../pages/polls/CreatePollPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_POLLS_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'pollAbout',
        component: () => import('../pages/polls/AboutPollPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_POLLS_GET]
        }
      },
      {
        path: ':id/edit',
        name: 'pollEdit',
        component: () => import('../pages/polls/EditPollPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_POLLS_UPDATE]
        }
      },
      {
        path: 'list',
        name: 'pollList',
        component: () => import('../pages/polls/ListPollPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_POLLS_GET]
        }
      }
    ]
  },
  {
    path: '/covenants',
    name: 'covenants',
    redirect: '/covenants/list',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: 'create',
        name: 'covenantCreate',
        component: () => import('../pages/covenants/CreateCovenantPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_COVENANTS_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'covenantAbout',
        component: () => import('../pages/covenants/AboutCovenantPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_COVENANTS_GET]
        }
      },
      {
        path: ':id/edit',
        name: 'covenantEdit',
        component: () => import('../pages/covenants/EditCovenantPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_COVENANTS_UPDATE]
        }
      },
      {
        path: 'list',
        name: 'covenantList',
        component: () => import('../pages/covenants/ListCovenantPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_COVENANTS_GET]
        }
      }
    ]
  },
  {
    path: '/users',
    name: 'users',
    redirect: '/users/list',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: 'list',
        name: 'usersList',
        component: () => import('../pages/users/ListUsersPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_USERS_GET]
        }
      },
      {
        path: 'attrs',
        name: 'userAttrsList',
        component: () => import('../pages/users/UserAttrsListPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_USER_ATTRS_GET]
        }
      },
      {
        path: ':id/about',
        name: 'userAbout',
        component: () => import('../pages/users/AboutUserPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_USERS_GET]
        }
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/NotFoundView.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  if (!store.getters.isAuthenticated && to.name !== 'login' && auth.readToken()) {
    try {
      const credentials = auth.readToken()
      if (credentials) {
        await auth.login(credentials)
      }
    } catch (error: any) {
      if (error.code === E_ACCESS || error.code === E_LOGIN) {
        auth.logout()
      } else {
        ElMessage.error('Щось пішло не так')
        throw error
      }
    }
  }

  // ПЕРЕВІРИТИ що є боти, та є активні Месенджери
  const hasActiveBots = store.getters['bots/hasActiveBots']
  const hasActiveMessengers = store.getters['bots/getActiveBot']?.hasActiveMessengers()
  
  if (!auth.isAuthenticated() && to.name !== 'login') {
    console.log(1);
    return { name: 'login' }
  } else if (auth.isAuthenticated() && (!hasActiveBots || !hasActiveMessengers) && (to.name !== 'home' && to.name !== 'botCreate' && to.name !== 'botAbout' && to.name !== 'botEdit')) {
    console.log(2);
    return { name: 'home' }
  } else if (auth.isAuthenticated() && to.name === 'login') {
    console.log(3);
    return { name: 'home' }
  }

  // перевірка пермішена
  const admin = store.state.admin
  const access = to.meta.access as string[] | undefined 

  if (access?.length) {   
    if (!admin || !admin.permissions.length || !admin.checkPermissions(access)) {
      console.error('access denied', to.name);
      return { name: 'notFound' }
    }
  }
})

export default router
