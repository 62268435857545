import {store} from '@/store'
import router from '@/router'
import apiRequest from './apiRequest'
import {API_METHODS, BOT_ACTIVE_LS_KEY} from '@/constants'
import {RawAdmin} from '@/types/RawAdmin'
import {RawBot} from '@/types/RawBot'

export async function login (credentials: string) {
  const admin: RawAdmin = await apiRequest({method: API_METHODS.auth.login, data: {credentials}})
  store.commit('setAdmin', admin)
  store.commit('setActiveCompany', admin.companyId);
  setToken(credentials)

  const botsList: RawBot[] = await store.dispatch('bots/getList')
  const savedActiveBotId = localStorage.getItem(BOT_ACTIVE_LS_KEY)
  const savedBot = botsList.find(bot => bot._id === savedActiveBotId)
  store.commit('bots/setActiveBot', savedBot?._id || botsList[0]?._id || '')
}

export async function logout () {
  store.commit('setAdmin', null);
  store.commit('setActiveCompany', '');
  store.commit('bots/setActiveBot', '')
  store.state.userAttrs.userAttrsByIdMap = {}
  store.state.userAttrs.userAttrsBySlugMap = {}
  store.state.bots.bots = {}
  store.state.content.expandedNodes = []
  store.state.content.nodes = {}
  store.state.mailings.mailings = {}
  store.state.users.users = {}
  clearToken();
  router.push({name: 'login'})
}

export function isAuthenticated () {
  return readToken()
}

export function readToken () {
  return localStorage.getItem(`auth_token`)
}

export function clearToken () {
  return localStorage.removeItem(`auth_token`)
}

export function setToken (token: string) {
  try {
    return localStorage.setItem(`auth_token`, token)
  } catch (error) {
    throw error;
  }
}


export default {
  isAuthenticated,
  readToken,
  clearToken,
  setToken,
  login,
  logout
}