<template>
  <a href="/" class="logo">
    <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 0H39.9808C22.9084 0 13.6392 5.18482 13.6392 14.784C13.6392 24.2631 22.9486 29.4061 39.9808 29.4061H40V0Z"
        fill="black" />
      <path
        d="M12.2185 27.8006C4.10714 25.6834 0 21.2802 0 14.6299C0 14.6075 4.63529e-05 14.5851 0.000139063 14.5628C0.000139063 8.34915 4.37241 3.84063 12.2185 1.55526V27.8006Z"
        fill="black" />
    </svg>
    <div class="logo__text">
      <slot></slot>
    </div>
  </a>
</template>
<style lang="scss">
.logo {
  display: flex;
  column-gap: 10px;
  align-items: center;

  img {
    width: 32px;
  }

  &__text {
    font-weight: 700;
    font-size: 18px;
  }
}</style>