import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '../../assets/icons/tg_white.svg'
import _imports_1 from '../../assets/icons/vb_white.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "Telegram"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: "Viber"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ui-messenger-icon', 'ui-messenger-icon--' + $props.messenger, {isDisabled: $props.disabled}]),
    style: _normalizeStyle({width: $props.size + 'px', height: $props.size + 'px'})
  }, [
    ($props.messenger === 'telegram')
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("v-if", true),
    ($props.messenger === 'viber')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("v-if", true)
  ], 6 /* CLASS, STYLE */))
}