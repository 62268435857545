import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import {API_METHODS} from '@/constants';
import apiRequest from '@/services/apiRequest';
import auth from '../services/auth';
import content, {ContentStore} from './modules/content'
import bots, {BotsStore} from './modules/bots'
import users, {UsersStore} from './modules/users'
import userAttrs, {UserAttrsStore} from './modules/userAttrs'
import mailings, {MailingsStore} from './modules/mailings'
import Admin from '@/models/content/Admin';
import {RawAdmin} from '@/types/RawAdmin';
import polls, {PollsStore} from './modules/polls';
import pollResponse, {PollResponseStore} from './modules/pollResponse';
import covenants, {CovenantsStore} from './modules/covenants';
import covenantResponse, {CovenantResponseStore} from './modules/covenantResponse';
import viberBg, {ViberBgState} from './modules/viberBg';

export interface RootState {
  appVersion: string,
  isLoading: boolean
  admin: Admin | null
  activeCompany: string
  content: ContentStore,
  bots: BotsStore
  users: UsersStore,
  userAttrs: UserAttrsStore,
  mailings: MailingsStore,
  polls: PollsStore,
  pollResponse: PollResponseStore,
  covenants: CovenantsStore,
  covenantResponse: CovenantResponseStore,
  viberBg: ViberBgState,
}

export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore<RootState>({
  // @ts-ignore
  state: {
    isLoading: false,
    admin: null,
    appVersion: '',
    activeCompany: ''
  },
  mutations: {
    toggleLoading (state, val) {
      state.isLoading = !!val
    },
    setAdmin (state, data: RawAdmin | null) {
      if (data) {
        state.admin = new Admin(data)
      } else {
        state.admin = null
      }
    },
    setActiveCompany (state, id: string) {
      state.activeCompany = id
    }
  },
  getters: {
    isAuthenticated: (state):boolean => {
      return !!state.admin
    }
  },
  actions: {
    clearGlobalData () {
      store.state.userAttrs.userAttrsByIdMap = {}
      store.state.userAttrs.userAttrsBySlugMap = {}
      store.state.bots.bots = {}
      store.state.content.expandedNodes = []
      store.state.content.nodes = {}
      store.state.mailings.mailings = {}
      store.state.users.users = {}
    },
    resetGlobalData () {
      store.state.userAttrs.userAttrsByIdMap = {}
      store.state.userAttrs.userAttrsBySlugMap = {}
      store.state.content.expandedNodes = []
      store.state.content.nodes = {}
      store.state.mailings.mailings = {}
      store.state.users.users = {}
    }
  },
  modules: {
    content,
    bots,
    users,
    userAttrs,
    mailings,
    polls,
    pollResponse,
    covenantResponse,
    covenants,
    viberBg
  }
})

export function useStore () {
  return baseUseStore(key)
}

export default store