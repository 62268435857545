import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawMailing} from '@/types/RawMailing';
import Mailing from '@/models/content/Mailing';

export interface MailingsStore {
  mailings: Dictionary<Mailing>
}

const mailings = {
  namespaced: true,
  state: () => ({
    mailings: {},
  }),
  mutations: {
    setMailing (state, data: RawMailing) {
      const mailing = state.mailings[data._id]
      if (mailing) {
        mailing.setData(data)
      } else {
        state.mailings[data._id] = new Mailing(data)
      }
    }
  },
  getters: {},
  actions: {
    async createMailing (_, data) {
      return await apiRequest({method: API_METHODS.mailings.create.mailing, data: {mailing: data}})
    },
    async getMailing (context, id: string) {
      const {mailing} = await apiRequest({method: API_METHODS.mailings.get.mailing, data: {id}})
      if (!mailing) {
        return null
      }
      context.commit('setMailing', mailing)
      return context.state.mailings[mailing._id]
    },
    async searchMailings (context, {query = {}, size = 10000, from = 0, search}: {query: any, size: number, from: number, search: string}) {
      const {mailings, total} = await apiRequest({method: API_METHODS.mailings.get.list, data: {query, search, size, from, sort: {'_ct': -1}}})

      const list: Mailing[] = []

      for (const mailing of mailings as RawMailing[]) {
        context.commit('setMailing', mailing)
        list.push(context.state.mailings[mailing._id])
      }

      return {mailings: list, total}
    },
    async updateMailing (_, {id, data}) {
      return await apiRequest({method: API_METHODS.mailings.update.mailing, data: {id, fields: data}})
    },
    async planeMailing (_, {id, date}) {
      return await apiRequest({method: API_METHODS.mailings.update.planeMailing, data: {id, date}})
    },
    async sendMailing (_, data) {
      return await apiRequest({method: API_METHODS.mailings.create.sendMailing, data})
    },
    async deleteMailing (_, {id}) {
      return await apiRequest({method: API_METHODS.mailings.delete.mailing, data: {id}})
    }
  }
} as Module<MailingsStore, RootState>;

export default mailings;