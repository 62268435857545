import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ui-table" }
const _hoisted_2 = { class: "ui-table__table" }
const _hoisted_3 = { class: "ui-table__header" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "ui-table__body"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "ui-table__cell",
  style: {"width":"40px"}
}
const _hoisted_9 = { class: "ui-table__index" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "ui-table__empty"
}
const _hoisted_12 = {
  key: 0,
  class: "ui-table__row"
}
const _hoisted_13 = ["colspan"]
const _hoisted_14 = {
  key: 1,
  class: "ui-table__footer"
}
const _hoisted_15 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_ArrowRightBold = _resolveComponent("ArrowRightBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        ($props.selectable)
          ? (_openBlock(), _createElementBlock("th", _hoisted_4))
          : _createCommentVNode("v-if", true),
        (!$props.hideIndex)
          ? (_openBlock(), _createElementBlock("th", _hoisted_5, "№"))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (col) => {
          return (_openBlock(), _createElementBlock("th", null, _toDisplayString(col.label), 1 /* TEXT */))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]),
      ($props.data.length)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (item, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("tr", {
                  class: "ui-table__row",
                  onClick: ($event: any) => ($setup.onRowClick(item))
                }, [
                  ($props.selectable)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                        _createVNode(_component_el_checkbox, {
                          modelValue: $setup.isRowChecked(item[$props.keyName]),
                          onChange: ($event: any) => ($setup.onCheckClick(item))
                        }, null, 8 /* PROPS */, ["modelValue", "onChange"])
                      ]))
                    : _createCommentVNode("v-if", true),
                  (!$props.hideIndex)
                    ? (_openBlock(), _createElementBlock("td", {
                        key: 1,
                        class: "ui-table__cell",
                        style: _normalizeStyle({width: $props.config.expandedRow ? '90px' : '70px'})
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", null, _toDisplayString(i + 1), 1 /* TEXT */),
                          ($props.config.expandedRow)
                            ? (_openBlock(), _createBlock(_component_el_icon, {
                                key: 0,
                                class: _normalizeClass(['ui-table__expand-icon', {isActive: $setup.isRowExpanded(i)}]),
                                onClick: _withModifiers(($event: any) => ($setup.onExpandedIconClick(i)), ["stop"]),
                                size: 12
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ArrowRightBold)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "onClick"]))
                            : _createCommentVNode("v-if", true)
                        ])
                      ], 4 /* STYLE */))
                    : _createCommentVNode("v-if", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (colOptions, colName) => {
                    return (_openBlock(), _createElementBlock("td", {
                      class: "ui-table__cell",
                      style: _normalizeStyle({width: colOptions.width ? colOptions.width + 'px' : 'auto'})
                    }, [
                      (colOptions.component)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(colOptions.component), {
                            key: 0,
                            data: item[colName],
                            scope: item,
                            colName: colName,
                            onReload: $setup.onReload
                          }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["data", "scope", "colName"]))
                        : (!colOptions.component && !$props.loading)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (item[colName])
                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item[colName]), 1 /* TEXT */))
                                : (_openBlock(), _createElementBlock("span", _hoisted_11, "Немає даних"))
                            ], 64 /* STABLE_FRAGMENT */))
                          : _createCommentVNode("v-if", true)
                    ], 4 /* STYLE */))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ], 8 /* PROPS */, _hoisted_7),
                ($setup.isRowExpanded(i))
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
                      _createElementVNode("td", {
                        colspan: Object.keys($setup.columns).length + 1
                      }, [
                        ($props.config.expandedRowComponent)
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.config.expandedRowComponent), {
                              key: 0,
                              row: item,
                              onReload: $setup.onReload
                            }, null, 40 /* PROPS, HYDRATE_EVENTS */, ["row"]))
                          : _createCommentVNode("v-if", true)
                      ], 8 /* PROPS */, _hoisted_13)
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("tfoot", _hoisted_14, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                colspan: Object.keys($setup.columns).length + 1
              }, "Немає даних", 8 /* PROPS */, _hoisted_15)
            ])
          ]))
    ])
  ])), [
    [_directive_loading, $props.loading]
  ])
}