import {API_BASE_URL, E_ACCESS} from '@/constants'
import axios from 'axios'
import auth from './auth';
import {store} from '@/store'


const HTTP = axios.create({
  baseURL: API_BASE_URL
})


export default async function ({url, method, data = {}, headers = {}}: {url?: string, method: string, data?: any, headers?: any}) {
  try {

    if (auth.readToken()) {
      headers['Authorization'] = 'Basic ' + auth.readToken()
    }

    const activeCompany = store.state.activeCompany
    const activeBot = store.state.bots.activeBotId

    const params: {companyId?: string, botId?: string} = {}

    if (activeCompany) {
      params.companyId = activeCompany
    }

    if (activeBot) {
      params.botId = activeBot
    }
    

    const response = await HTTP({
      url,
      method: 'post',
      data: {
        method,
        params: data
      },
      params,
      headers
    });

    if (response.data.error) {
      if (response.data.error.code === E_ACCESS) {
        auth.logout()
      }

      throw response.data.error
    }
    
    return response.data.result;
  } catch (error) {
    throw error;
  }
}