import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ui-block__wrapper" }
const _hoisted_2 = { class: "ui-block__label" }
const _hoisted_3 = {
  key: 0,
  class: "ui-block__rigthbar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowDownBold = _resolveComponent("ArrowDownBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_ArrowUpBold = _resolveComponent("ArrowUpBold")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ui-block', {isСlosable: $props.closable}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.label || _ctx.$slots.label)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['ui-block__header', , {isСlosable: $props.closable}]),
            onClick: $setup.toggleClose
          }, [
            _createElementVNode("span", _hoisted_2, [
              _renderSlot(_ctx.$slots, "label"),
              _createTextVNode(" " + _toDisplayString($props.label), 1 /* TEXT */)
            ]),
            _renderSlot(_ctx.$slots, "header", { class: "ui-block__slot-header" }),
            ($props.closable)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(['ui-block__close-icon', {isClose: $setup.isClose}])
                }, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ArrowDownBold)
                    ]),
                    _: 1 /* STABLE */
                  })
                ], 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(['ui-block__content', {'ui-block__content--no-padding': !$props.label && !_ctx.$slots.label}])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2 /* CLASS */), [
        [_vShow, !$props.closable || !$setup.isClose]
      ])
    ]),
    ($props.deleted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "ui-block__option",
            title: "Видалити блок",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete')))
          }, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_Delete)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", {
            class: "ui-block__option",
            title: "Перемістити вище",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('shiftUp')))
          }, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowUpBold)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", {
            class: "ui-block__option",
            title: "Перемістити нижче",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('shiftDown')))
          }, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowDownBold)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}