import ModelInterface from '@/models/ModelInterface'
import {RawAdmin} from '@/types/RawAdmin'

export default class Admin extends ModelInterface<RawAdmin> {

  public checkPermission (name: string): boolean {
    return this.permissions.some(permission => permission === name)
  }

  public checkPermissions (names: string[]): boolean {
    return names.some(item => this.permissions?.includes(item))
  }

  get companyId () {
    return this.data.companyId
  }

  get name () {
    return this.data.name
  }

  get permissions () {
    return this.data.permissions || []
  }
}