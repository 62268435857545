import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawPoll} from '@/types/RawPoll';
import Poll from '@/models/content/Poll';

export interface PollsStore {
  polls: Dictionary<Poll>
}

const polls = {
  namespaced: true,
  state: () => ({
    polls: {},
  }),
  mutations: {
    setPoll (state, data: RawPoll) {
      const mailing = state.polls[data._id]
      if (mailing) {
        mailing.setData(data)
      } else {
        state.polls[data._id] = new Poll(data)
      }
    }
  },
  getters: {},
  actions: {
    async createPoll (_, data) {
      return await apiRequest({method: API_METHODS.polls.create.poll, data: {poll: data}})
    },
    async getPoll (context, id: string) {
      const {poll} = await apiRequest({method: API_METHODS.polls.get.poll, data: {id}})
      if (!poll) {
        return null
      }
      context.commit('setPoll', poll)
      return context.state.polls[poll._id]
    },
    async searchPolls (context, {query = {}, size = 10000, from = 0, search}: {query: any, size: number, from: number, search: string}) {
      const {documents: polls, total} = await apiRequest({method: API_METHODS.polls.get.pollsList, data: {query, search, size, from, sort: {'_ct': -1}}})

      const list: Poll[] = []

      for (const poll of polls as RawPoll[]) {
        context.commit('setPoll', poll)
        list.push(context.state.polls[poll._id])
      }

      return {mailings: list, total}
    },
    async updatePoll (_, {id, data}) {
      return await apiRequest({method: API_METHODS.polls.update.poll, data: {id, fields: data}})
    },
    async planePoll (_, {id, startDate, endDate}) {
      return await apiRequest({method: API_METHODS.polls.update.planePoll, data: {id, startDate, endDate}})
    },
    async cancelPoll (_, {id}) {
      return await apiRequest({method: API_METHODS.polls.update.cancelPoll, data: {id}})
    },
    async deletePoll (_, {id}) {
      return await apiRequest({method: API_METHODS.polls.delete.poll, data: {id}})
    },
    async closePoll (_, {id}) {
      return await apiRequest({method: API_METHODS.polls.update.closePole, data: {id}})
    }
  }
} as Module<PollsStore, RootState>;

export default polls;