import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "ui-avatar__name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_empty_image = _resolveComponent("ui-empty-image")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ui-avatar', 'ui-avatar--' + $props.size])
  }, [
    ($props.src)
      ? (_openBlock(), _createBlock(_component_el_image, {
          key: 0,
          class: "ui-avatar__image",
          src: $props.src,
          "preview-src-list": [$props.src],
          fit: "cover"
        }, {
          error: _withCtx(() => [
            _createVNode(_component_ui_empty_image)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["src", "preview-src-list"]))
      : ($props.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.name![0].toLocaleUpperCase()), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}