
import { defineComponent } from 'vue';
import navigation from '@/navigation';
import PrimaryNav from './PrimaryNav.vue';
import {CDN_URL} from '@/constants'

export default defineComponent({
  name: 'Sidebar',
  components: {
    PrimaryNav
  },
  data () {
    return {
      navigation,
      CDN_URL
    }
  },
  computed: {
    appVersion (): string {     
      return this.$store.state.appVersion;
    }
  }
});
