import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "primary-nav" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "primary-nav__children" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.navigation, (parent) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        ($setup.isRouteVissible(parent.access))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['primary-nav__parent', {isActive: $setup.isRoutActive(parent.route)}, {isDisable: $setup.isDisable}])
            }, [
              (parent.route)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: parent.route
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(parent.name), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(parent.name), 1 /* TEXT */)),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parent.children, (child) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    ($setup.isRouteVissible(child.access))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(['primary-nav__item', {isActive: $setup.isRoutActive(child.route)}])
                        }, [
                          (child.route)
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                to: child.route
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(child.name), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(child.name), 1 /* TEXT */))
                        ], 2 /* CLASS */))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}