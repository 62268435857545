<template>
<div class="ui-empty-image">
  <div class="ui-empty-image__wrapp">
    <el-icon :size="20"><PictureFilled /></el-icon>
    <div class="ui-empty-image__text">Помилка під час завантаження</div>
  </div>
</div>
</template>
<style lang="scss">
.ui-empty-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapp {
    text-align: center;
    color: gray;
  }

  &__text {
    margin-top: 5px;
    font-size: 12px;
  }
}
</style>