import ContentNode from '@/models/content/ContentNode';
import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import {RawNode} from '@/types/RawNode';
import apiRequest from '@/services/apiRequest';
import {API_METHODS, NODE_STATUS_DRAFT, NODE_STATUS_IN_TEST, NODE_STATUS_PUBLISHED} from '@/constants';
import {TreeNodeData} from 'element-plus/es/components/tree-v2/src/types';

export interface ContentStore {
  nodes: Dictionary<ContentNode>,
  expandedNodes: string[]
}

const content = {
  namespaced: true,
  state: () => ({
    nodes: {},
    expandedNodes: []
  }),
  mutations: {
    setNode (state, nodeData: RawNode) {
      const node = state.nodes[nodeData._id]
      if (node) {
        node.setData(nodeData)
      } else {
        state.nodes[nodeData._id] = new ContentNode(nodeData)
      }
    },
    addExpandedNode (state, id: string) {
      state.expandedNodes.push(id)
    },
    removeExpandedNode (state, id: string) {
      const index = state.expandedNodes.findIndex(_id => _id === id)
      if (index >= 0) {
        state.expandedNodes.splice(index, 1)
      }
    }
  },
  getters: {},
  actions: {
    async getNode (context, id: string) {
      const {node} = await apiRequest({method: API_METHODS.contentNode.get.node, data: {id}})
      if (!node) {
        return null
      }
      context.commit('setNode', node)
      return context.state.nodes[node._id]
    },
    async getTree (context, {status, bot, filters}) {
      const data: any = {
        query: {
          status: status?.length && status || [NODE_STATUS_PUBLISHED, NODE_STATUS_IN_TEST, NODE_STATUS_DRAFT],
          bot
        },
        filters
      }

      const {tree, map} = await apiRequest({method: API_METHODS.contentNode.get.tree, data})
      if (!tree) {
        return null
      }
      return {tree, map}
    },
    async getList (context,  {status, bot}) {
      const data: any = {
        query: {
          status: status?.length && status || [NODE_STATUS_PUBLISHED, NODE_STATUS_IN_TEST, NODE_STATUS_DRAFT],
          bot
        }
      }

      const {list} = await apiRequest({method: API_METHODS.contentNode.get.list, data})
      if (!list) {
        return []
      }
      return list
    },
    async createNode (context, data) {
      return await apiRequest({method: API_METHODS.contentNode.create.node, data: {node: data}})
    },
    async updateNode (context, {id, data}) {
      return await apiRequest({method: API_METHODS.contentNode.update.node, data: {id, fields: data}})
    },
    async updateParent (context, {id, parentId, order}) {
      return await apiRequest({method: API_METHODS.contentNode.update.parent, data: {id, parentId, order}})
    },
    async deleteNode (context, id: string) {
      return await apiRequest({method: API_METHODS.contentNode.delete.node, data: {id}})
    },
  }
} as Module<ContentStore, RootState>;

export default content;