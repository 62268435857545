import ModelInterface from '@/models/ModelInterface'
import {RawCovenantResponse} from '@/types/RawCovenantResponse'

export default class CovenantResponse extends ModelInterface<RawCovenantResponse> {

  public getResponseByQuestId (id: string) {
    const response = this.response.find(res => res.questId === id)
    return response
  }

  get status () {
    return this.data.status || ''
  }

  get userPhone (): string {
    return this.data.user?.phone || ''
  }

  get covenantId () {
    return this.data.covenantId
  }

  get userFullName (): string {
    let fullName = ''
    fullName += this.data.user?.lastName ? this.data.user?.lastName + ' ' : ''
    fullName += this.data.user?.firstName ? this.data.user?.firstName + ' ' : ''
    fullName += this.data.user?.middleName ? this.data.user?.middleName + ' ' : ''
    return fullName;
  }

  get userMsgrs () {
    return this.data.user?.msgrs
  }

  get response () {
    return this.data.response || []
  }
}